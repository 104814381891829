<template>
  <div>
    <div class="image-input__wrapper">
      <div v-ripple class="image-input" :class="{ 'image-input--error': errorMessages.length }" @click="$refs.realFileInput.click()">
        <v-icon x-large>
          backup
        </v-icon>
        <div v-if="!currentImage" class="secondary--text" v-text="$t('formFields.chooseImage')" />
        <div v-else-if="currentImageType === 'string'" class="image-input__overlay">
          <img class="image-input__overlay-image" :src="currentImage">
        </div>
        <div v-else-if="currentImageType === 'file'" class="image-input__overlay">
          <img class="image-input__overlay-image" :src="currentImageDataUrl">
        </div>
      </div>
      <input
        ref="realFileInput" type="file"
        accept="image/png, image/jpeg"
        style="display: none;"
        :disabled="disabled"
        @change="cropImage($event.target.files[0])"
        @click="$event.target.value = null"
      >
      <v-btn
        v-if="canBeDeleted && currentImage"
        class="delete-btn"
        color="warning"
        fab small absolute bottom right
        @click="currentImage = null; $emit('input', null)"
      >
        <v-icon>clear</v-icon>
      </v-btn>
      <v-btn
        v-if="currentImageType === 'string'"
        class="download-btn"
        color="primary"
        fab small absolute bottom right
        :href="currentImage" target="_blank" download
      >
        <v-icon>get_app</v-icon>
      </v-btn>
    </div>
    <v-input :error-messages="errorMessages" error />
    <v-dialog v-model="cropperDialog" max-width="800px">
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-4" v-text="$t('formFields.cropImage')" />
        <cropper
          ref="cropper"
          class="cropper"
          :src="imageToCrop"
          :min-height="10"
          :min-width="10"
          :stencil-props="{
            aspectRatio: width / height,
            handlers: { eastNorth: true, north: false, westNorth: true, west: false, westSouth: true, south: false, eastSouth: true, east: false }
          }"
        />
        <v-card-actions class="pa-0 mt-8">
          <v-btn color="primary" small block width="120" @click="saveImageFile" v-text="$t('common.save')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import { getFileUrl } from '@/utils/fileReader'

export default {
  name: 'ImageField',
  components: {
    Cropper,
  },
  props: {
    value: { type: [String, File], default: null },
    width: { type: Number, default: 1500 },
    height: { type: Number, default: 844 },
    errorMessages: { type: String, default: '' },
    canBeDeleted: { type: Boolean, default: false },
    suffix: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      cropperDialog: false,
      currentImage: this.value, // Only gets evaluated at component creation, so it's always an url or null
      imageToCrop: null,
      imageName: null,
      currentImageDataUrl: null,
    }
  },
  computed: {
    currentImageType: ({ currentImage }) => {
      if (typeof currentImage === 'string') return 'string'
      if (typeof currentImage === 'object' && currentImage instanceof File) return 'file'
    },
  },
  async created() {
    if (this.currentImageType === 'file') this.currentImageDataUrl = await getFileUrl(this.currentImage)
  },
  methods: {
    async cropImage(imageFile) {
      this.$store.commit('loader/show')
      this.imageName = imageFile.name.substr(0, imageFile.name.lastIndexOf('.')) || imageFile.name
      const imageBase64 = await getFileUrl(imageFile)
      this.imageToCrop = imageBase64
      this.cropperDialog = true
      this.$nextTick(() => {
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => ({ width: imageSize.width, height: imageSize.height }))
      })
      setTimeout(() => { this.$store.commit('loader/hide') }, 500)
    },
    async saveImageFile() {
      const { canvas } = this.$refs.cropper.getResult()
      if (!canvas) return
      this.$store.commit('loader/show')
      const resizedCanvas = document.createElement('canvas')
      resizedCanvas.width = this.width
      resizedCanvas.height = this.height
      resizedCanvas.getContext('2d').drawImage(canvas, 0, 0, this.width, this.height)
      const imageType = this.hasTransparency(resizedCanvas) ? 'image/png' : 'image/jpeg'
      const imageBlob = await new Promise(resolve => { resizedCanvas.toBlob(blob => resolve(blob), imageType, 0.8) })
      const newImageName = `${this.imageName}${this.suffix ?? ''}.${imageType.split('/').at(-1)}`
      const imageFile = new File([imageBlob], newImageName)
      this.$emit('input', imageFile)
      this.currentImage = resizedCanvas.toDataURL()
      this.cropperDialog = false
      this.$store.commit('loader/hide')
    },
    hasTransparency(canvas) {
      const context = canvas.getContext('2d')
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data
      const checkPoints = [...Array(Math.floor((imageData.length + 1) / 4))].map((v, i) => 4 * i + 3)
      return checkPoints.some(point => imageData[point] < 255)
    },
  },
}
</script>

<style lang="scss">
.image-input__wrapper {
  position: relative;
}

.image-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  background-color: #f5f5f5;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;

  &--error {
    border: 2px solid #e02020;
  }
}

.image-input__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.image-input__overlay-image {
  object-fit: cover;
  background-color: #ccc;
}

.cropper {
  max-height: 400px;
  overflow: hidden;
}

.cropper .vue-advanced-cropper__background {
  background: #ccc;
}

.delete-btn {
  right: 70px !important;
}
</style>
